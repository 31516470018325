<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">合作加盟</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input label="手机号" v-model="pageParam.params.mobile" />
            </le-search-form>
            <le-pagview ref="cooperateListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.cooperateList" :pageSizeDefault='20'>
                <el-table 
                    ref="cooperateList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="nameId" label="手机号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.customerMobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="transactionId" label="姓名" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.customerName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ $getDay.getTimeStampToStr(row.createTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="problemDescription" label="合作类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.customerType=='1'?'个人':'公司' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unitPriceId" label="地区" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.province }}{{ row.city }}{{ row.area }}{{ row.address }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationId" label="合作内容" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.content || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="备注" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.remark || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="70" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="添加备注" placement="top">
                                <img src="../../assets/icon/option-msg.png" class="a-wh-16" @click="handleApply(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <cooperate-mark ref="cooperateMark" @handlerSuccess='handlerApplySuccess'></cooperate-mark>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import util from '../../../src/utils/util'
    import CooperateMark from './child/cooperate-mark.vue';
    export default {
        components: {
                CooperateMark
        },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: null,
            }
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
            }),
        },
        created () {
            this.pageParam = {
                url: this.$Config.apiUrl.companyAdviceList,
                method: "post",
                params: {
                    mobile: '',
                },
                freshCtrl: 1,
            }
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: '',
                    complaintStartTime: '',
                    complaintEndTime: '',
                    complaintState: '',
                    mobile: '',
                    orderSn: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['cooperateListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 添加备注
            handleApply (datas) {
                this.$refs['cooperateMark'].cooperateInfo = datas
                this.$refs['cooperateMark'].dialogVisible = true
            },
            handlerApplySuccess (datas) {
                this.pageParam.freshCtrl++;
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.complaint-item{
    margin-right: 20px;
    width: 400px;
    padding: 18px 0;
    border-radius: 12px;
    .title{
        font-size: 16px;
        font-weight: 400;
    }
    .waitNum{
        font-size: 40px;
        font-weight: 500;
        // color: #007AFF;
        padding: 0 6px;
    }
    .waitText{
        font-size: 16px;
        font-weight: 400;
        // color: #007AFF;
    }
    .overtimeText{
        font-size: 14px;
        color: #666666;
    }
    .overtimeNum{
        font-size: 20px;
        color: #F56C6C;
        padding: 0 6px;
    }
}
.complaint-item-wait{
    background: #EAF4FF;
    // border: 2rpx solid #6CB1FF;
    color: #007AFF;
}
.complaint-item-doing{
    background: #ECFFFA;
    // border: 2rpx solid #6CCFB7;
    color: #0FBC93;
}
.s-btn-add{
    width: 135px;
}
// /deep/ .s-search-label{
//     width: 100px
// }
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>